import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./helper/PrivateRoute";
import PublicRoute from "./helper/PublicRoute";
import "./scss/style.scss";

// Toont een 'spinning'-icon wanneer de pagina nog gerendered word
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Wachtwoord = React.lazy(() => import("./views/pages/WachtwoordVergeten"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ResetPassword = React.lazy(() => import("./views/pages/resetpassword/ResetPassword"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <PublicRoute
              exact
              restricted={true}
              path="/login"
              name="Login Page"
              component={(props) => <Login {...props} />}
            />
            <PublicRoute
              exact
              restricted={true}
              path="/register"
              name="Register Page"
              component={(props) => <Register {...props} />}
            />
            <PublicRoute
              exact
              restricted={true}
              path="/wachtwoordvergeten"
              name="Wachtwoord page"
              component={(props) => <Wachtwoord {...props} />}
            />
            <PublicRoute
              path="/resetPassword"
              name="Reset Password"
              component={(props) => <ResetPassword {...props} />}
            />
            <PrivateRoute
              exact
              path="/404"
              name="Page 404"
              component={(props) => <Page404 {...props} />}
            />
            <PrivateRoute
              exact
              path="/500"
              name="Page 500"
              component={(props) => <Page500 {...props} />}
            />
            <PrivateRoute
              path="/"
              name="Home"
              component={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
