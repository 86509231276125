import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * @description react-i18next is een internationalisatie framework voor React.
 * In de folder 'locales' bevinden zich meerdere json bestanden waarin de gewenste
 * tekst als value wordt opgeslaan bij een bepaalde key.
 */

// local imports
import en from "./locales/en.json";
import nl from "./locales/nl.json";
import fr from "./locales/fr.json";

let language = localStorage.getItem("language");
//NL als standaardtaal instellen.
if (language === undefined) {
  language = "nl";
}

i18n.use(initReactI18next).init({
  resources: {
    en: { translations: en },
    nl: { translations: nl },
    fr: { translations: fr },
  },
  fallbackLng: "nl",
  lng: "nl",
  // debug only when not in production
  debug: process.env.NODE_ENV !== "production",
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;
