import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthenticationService from "./AuthenticationService";

// Reference: https://github.com/thanhbinhtran93/react-router-example
const PublicRoute = ({ component: Component, restricted, ...rest }) => {

    const [authChecked, setAuthChecked] = useState(false);
    const [isAuth, setIsAuth] = useState();

    useEffect(() => {
        AuthenticationService.isAuthenticated().then(res => {
            setIsAuth(res);
            setAuthChecked(true);
        });
    }, []);

    if (!authChecked) return <span>Data not available</span>;

    return (

        <Route {...rest} render={(props) => (
            (isAuth && restricted) ?
                < Redirect
                    to={{ pathname: "/dashboard", state: { from: props.location } }}
                />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;
