import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthenticationService from "./AuthenticationService";

// Reference: https://github.com/thanhbinhtran93/react-router-example
const PrivateRoute = ({ component: Component, ...rest }) => {

  const [authChecked, setAuthChecked] = useState(false);
  const [isAuth, setIsAuth] = useState();

  useEffect(() => {
    AuthenticationService.isAuthenticated().then(res => {
      setIsAuth(res);
      setAuthChecked(true);
    });
  }, []);

  if (!authChecked) return <span>Data not available</span>;

  return (
    <Route {...rest} render={(props) => (
      (isAuth) ?
        <Component {...props} />
        : < Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
    )} />
  );
};

export default PrivateRoute;
