import axios from "axios";
const { REACT_APP_URL } = process.env;

/**
 * @description axios request interceptor used to add
 * a x-access-token header, with the correct user access token,
 * to every http request sent to the back-end.
 */
 axios.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem("user"));
  
  if (user && user.accessToken) {
    config.headers["x-access-token"] = user.accessToken;
  }

  return config;
});

class AuthenticationService {
  signin = (email, password) => {
    return axios
      .post(`${REACT_APP_URL}/api/auth/signin`, { email, password })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  signOut() {
    localStorage.removeItem("user");
  }

  register = async (
    first_name,
    last_name,
    email,
    password,
    street,
    number,
    postcode,
    city,
    BTW_number,
    number_orde_der_dierenartsen,
    landline_phone,
    mobile_phone,
    intervallen
  ) => {
    return axios.post(`${REACT_APP_URL}/api/auth/signup`, {
      first_name,
      last_name,
      email,
      password,
      street,
      number,
      postcode,
      city,
      BTW_number,
      number_orde_der_dierenartsen,
      landline_phone,
      mobile_phone,
      intervallen,
    });
  };

  checkEmail = async (email) => {
    return axios.get(`${REACT_APP_URL}/api/auth/checkEmail`, {
      params: { email },
    });
  };

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  async isAuthenticated() {
    try {
      const url = `${REACT_APP_URL}/api/auth/isAuthenticated`;
      const res = await axios.get(url);
      // if status === 401 or 403 then logout and return false else true
      if (res.status === 401 || res.status === 403) {
        this.signOut();
        return false;
      } else {
        return true;
      }
    } catch (err) {
      this.signOut();
      return false;
    }
  }

  isAdmin = async () => {
    let isAuth = await this.isAuthenticated();
    if (isAuth) {
      const userid = this.getCurrentUser().id;
      return await axios.get(`${REACT_APP_URL}/api/auth/isAdmin`, {
        params: { userid },
      });
    }
    return 0;
  };

  async resetPassword(id, token, password) {
    const config = {
      headers: {
        "x-access-token": token,
      }
    };
    const data = {
      password: password
    };
    const url = `${REACT_APP_URL}/api/users/update_password/${id}`;
    return await axios.post(url, data, config);
  }

  async resetPasswordRequest(email) {
    const params = new URLSearchParams({
      email: email
    }).toString();
    const url = `${REACT_APP_URL}/api/auth/resetPassword?` + params;
    return await axios.get(url);
  }
}

export default new AuthenticationService();
